import Cookie from 'js-cookie';
import {
    DevicePlatform,
    getPlatformName,
    isTouchEnabled,
} from '@vault/uisdk/services/core/DeviceInformation';

function hasSkipVaultMobile() {
    return new URL(window.location).searchParams?.has('skipVaultMobile');
}

function cleanseUrlAndReload() {
    const thisUrl = new URL(window.location);
    const nextUrl = new URL(thisUrl);
    nextUrl.searchParams.delete('skipVaultMobile');
    if (nextUrl.href === thisUrl.href) {
        // reload instead of setting new location. Only reload will trigger a page refresh
        // on iOS if the user cancels the web-to-mobile linking action through the native iOS
        // dialog.
        window.location.reload();
    } else {
        // set new location since there is no native iOS/Android dialog to get past.
        window.location.assign(nextUrl.href);
    }
}

function skipVaultMobileAndReload() {
    Cookie.set('SKIP_VAULT_MOBILE', 'true');
    cleanseUrlAndReload();
}

function invalidateGeneralAndReload() {
    window.localStorage.setItem('VAULT_MOBILE_GENERAL_URL_INVALID', 'true');
    cleanseUrlAndReload();
}

async function getVaultMobileUrls() {
    let url = new URL('/ui/mobileRouter/execute', window.location.origin);
    url.searchParams.append('destURL', window.location.hash);
    const response = await fetch(url);
    const responseJson = await response.json();
    if (responseJson?.serverResult?.payload) {
        Cookie.set('SKIP_VAULT_MOBILE_SUPPORTED', 'true');
        return {
            limited: responseJson.serverResult.payload.limited,
            general: responseJson.serverResult.payload.general,
        };
    }
    throw new Error('Vault Mobile does not support this url');
}

async function tryUrl(url) {
    return new Promise((resolve, reject) => {
        window.location.href = url;
        setTimeout(() => {
            reject(new Error('URL was not opened'));
        }, 60);
    });
}

async function executeRouter() {
    const accessedByReload = window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload');
    const isAndroid = getPlatformName() === DevicePlatform.ANDROID;
    const isIOS =
        getPlatformName() === DevicePlatform.IOS ||
        (getPlatformName() === DevicePlatform.MACINTOSH && isTouchEnabled());
    const isMobileDevice = isAndroid || isIOS;
    Cookie.set('IS_VAULT_MOBILE_SUPPORTED_DEVICE', isMobileDevice ? 'true' : 'false');
    if (!isMobileDevice) {
        skipVaultMobileAndReload();
        return;
    }

    const isGeneralUrlInvalid = window.localStorage.getItem('VAULT_MOBILE_GENERAL_URL_INVALID');
    window.localStorage.removeItem('VAULT_MOBILE_GENERAL_URL_INVALID');
    if (!accessedByReload || isGeneralUrlInvalid) {
        let urls;
        try {
            urls = await getVaultMobileUrls();
        } catch (e) {
            skipVaultMobileAndReload();
            return;
        }

        //skip trying the URL if the parameter is provided.  Checked later because we need to first
        //evaluate if the URL is supported (call getVaultMobileUrls()) so that we know whether or not to show
        //the app banner on the login page
        const skipVaultMobileParam = hasSkipVaultMobile();
        if (skipVaultMobileParam) {
            skipVaultMobileAndReload();
            return;
        }

        //on android, always only launch using the 'general' URL.
        //on iOS, fist try the 'general' URL then, if not installed, try the 'limited' URL.
        const url = isIOS && isGeneralUrlInvalid ? urls.limited : urls.general;
        try {
            await tryUrl(url);
        } catch (e) {
            if ((isIOS && isGeneralUrlInvalid) || isAndroid) {
                skipVaultMobileAndReload();
            } else {
                invalidateGeneralAndReload();
            }
        }
    } else {
        skipVaultMobileAndReload();
    }
}
executeRouter();
