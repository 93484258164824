/** @format **/

export const DevicePlatform = Object.freeze({
    ANDROID: 'Android',
    CHROMEOS: 'ChromeOS',
    IOS: 'IOS',
    LINUX: 'Linux',
    MACINTOSH: 'Macintosh',
    WINDOWS: 'Windows',
});

const DevicePlatformDetection = {
    [DevicePlatform.ANDROID]: [
        {
            platform: /Android/,
        },
        {
            platform: /Linux/,
            userAgent: /^.*(Android).*$/,
        },
    ],
    [DevicePlatform.LINUX]: [
        {
            platform: /Linux/,
            userAgent: /^(?!.*(CrOS|Android)).*$/,
        },
    ],
    [DevicePlatform.CHROMEOS]: [
        {
            userAgent: /CrOS/,
        },
    ],
    [DevicePlatform.IOS]: [
        {
            platform: /(iPhone|iPod|iPad)/,
        },
    ],
    [DevicePlatform.MACINTOSH]: [
        {
            platform: /Mac/,
        },
    ],
    [DevicePlatform.WINDOWS]: [
        {
            platform: /Win/,
        },
    ],
};

/**
 * Determines if a device has touch support.
 *
 * @returns {boolean} true if the device has touch support.
 */
export function isTouchEnabled() {
    // if Pointer Events are supported, check maxTouchPoints
    if (window.PointerEvent && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
        return true;
    }

    // no Pointer Events...
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // check for any-pointer:coarse which mostly means touchscreen
        return true;
    }

    if (!window.PointerEvent && !('maxTouchPoints' in navigator)) {
        // last resort - check for exposed touch events API / event handler
        return window.TouchEvent || 'ontouchstart' in window;
    }

    return false;
}

/**
 * Finds the platform name for the device
 * @returns {string} platform name if available, otherwise null.
 */
export function getPlatformName() {
    const platform = Object.entries(DevicePlatformDetection).find(([, detectionTests]) =>
        detectionTests.some((detectionTest) =>
            Object.entries(detectionTest).every(([navigatorProperty, regexTest]) =>
                regexTest.test(window.navigator[navigatorProperty]),
            ),
        ),
    );
    return platform ? platform[0] : null;
}

/**
 * Determines if a device is a Windows device.
 *
 * @returns {boolean} true if the device is running Windows.
 */
function isWindowsDevice() {
    return DevicePlatform.WINDOWS === getPlatformName();
}

/**
 * Determines if the current device is a mobile device.
 *
 * @returns {boolean} true if the current device is has touch capability and if the device is not on Windows.
 */
export function isMobile() {
    return isTouchEnabled() && !isWindowsDevice();
}

const DeviceInformation = {
    getPlatformName,
    isTouchEnabled,
};
export default DeviceInformation;
